// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-farmers-advisors-js": () => import("./../../../src/pages/farmers-advisors.js" /* webpackChunkName: "component---src-pages-farmers-advisors-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-supporters-js": () => import("./../../../src/pages/partners-supporters.js" /* webpackChunkName: "component---src-pages-partners-supporters-js" */),
  "component---src-pages-thank-you-farmer-js": () => import("./../../../src/pages/thank-you-farmer.js" /* webpackChunkName: "component---src-pages-thank-you-farmer-js" */),
  "component---src-pages-thank-you-partner-js": () => import("./../../../src/pages/thank-you-partner.js" /* webpackChunkName: "component---src-pages-thank-you-partner-js" */),
  "component---src-pages-thank-you-us-growers-survey-js": () => import("./../../../src/pages/thank-you-us-growers-survey.js" /* webpackChunkName: "component---src-pages-thank-you-us-growers-survey-js" */),
  "component---src-pages-us-growers-eligibility-survey-js": () => import("./../../../src/pages/us-growers-eligibility-survey.js" /* webpackChunkName: "component---src-pages-us-growers-eligibility-survey-js" */)
}

